<template>
  <div class="px-4 lg:pl-10 pr-4 pt-3 lg:pt-10">
    <section
      class="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-3 mb-3"
    >
      <div>
        <h3 class="text-xl lg:text-xl font-bold text-dkgray">
          Biometric Accreditation
        </h3>
        <small class="text-xs text-purple-600 font-bold">
          Matches staff biometric to BVN Picture
        </small>
      </div>

      <div class="flex gap-4 items-center justify-end">
        <p class="text-dkgray">BVN Picture</p>
        <a :href="profilePic">
          <img
            :src="profilePic || require('@/assets/images/null_female.png')"
            alt="user bvn image"
            class="rounded-lg w-12 h-12 lg:w-16 lg:h-16 border border-gray-400"
        /></a>
      </div>
    </section>

    <section>
      <div
        v-if="getting"
        class="flex justify-center items-center h-[70vh] w-full"
      >
        <spinner class="w-20 h-20" color="oneGreen"></spinner>
      </div>

      <div
        v-else-if="!getting && !pageData"
        class="flex flex-col justify-center items-center w-screen h-screen"
      >
        <img
          src="@/assets/images/icons/broken_link.svg"
          alt="not found icon"
          class="w-40 h-42 mx-auto"
        />
      </div>

      <template v-else>
        <iframe
          :srcdoc="pageData"
          frameBorder="0"
          class="border-none w-full h-[70vh]"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: "AccreditationStart",

  data() {
    return {
      getting: true,
      pageData: null,
      userId: null,
      profilePic: null,
    };
  },

  async created() {
    this.userId = this.$route.params.id;
    await this.startBiometric();
  },

  methods: {
    async startBiometric() {
      try {
        this.getting = true;

        const payload = {
          key: this.BIOMETRIC_API_KEY,
          UserID: this.userId,
          ReturnUrl: `${window.location.origin}/admin/accreditation/biometric-return`, //?DataURL=&ImageID=&UserID=&status=,
          ErrorUrl: `${window.location.origin}/admin/accreditation/biometric-failed`,
        };

        const res = await this.$http.post("/biometric/capture", payload);

        const { data, imageUrl, canSkipBiometric } = res.data;

        this.pageData = data;
        this.profilePic = imageUrl;
        this.$store.commit("admin/saveData", {
          key: "biometricUser",
          data: { userId: this.userId, canSkipBiometric },
        });

        this.getting = false;
      } catch (error) {
        this.getting = false;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
